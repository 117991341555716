import React from 'react';

function RightArrowIcon({stroke}) {
    return (
        <svg width="17" height="16" className="ms-2" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.54004 8H12.8734" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.20703 3.3335L12.8737 8.00016L8.20703 12.6668" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default RightArrowIcon;