import React, { useEffect, useRef, useState, useContext } from "react";
import { useRouter } from "next/router";
//helper
import { jwtDecode } from "../../helper/jwtHelper";
import {Nav, Navbar, Dropdown, Container, Button} from "react-bootstrap";
// scss
import styles from "./header.module.scss";
// img
import logo from "../../public/Cheapestessay.webp";
import logoDesktop from "../../public/logoDesktop.png";
import studentBeans from "../../public/header/studentBeans.png";
import Link from 'next/link';
import useOutsideAlerter from "../../helper/useOutsideAlerter";
import RightArrowIcon from "../icons/rightArrowIcon";
import ukFlag from "../../public/ukFlag.png";
import {CA, UAE, UK, DEFAULT_LOCALES_LIST} from "../../helper/constantHelper";
import canadaFlag from "../../public/canadaFlag.png";
import uaeFlag from "../../public/uaeFlag.png";
import Image from "next/image";
import { ContextData } from "../../context/context";
import useDeviceWidth from "../../helper/useDeviceWidth";
import Img from "../Common/image";

function Header(props) {
  const { data } = useContext(ContextData);

  const { width } = useDeviceWidth();
  const [showDropdown, setShowDropdown] = useState(false);
  const howItWorksRef = useRef(null);
  const contactUsRef = useRef(null);
  const couponRef = useRef(null);
  const aboutRef = useRef(null);
  const writingToolRef = useRef(null);
  const samplesRef = useRef(null);
  const serviceRef = useRef(null);
  const [isLogin, setIsLogin] = useState(false);

  const { currLocale, linkLocale, isStudentBeanOpen, setIsStudentBeanOpen } = props;

  // get route
  const router = useRouter();
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > 50;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    })
  })

  useEffect(() => {
    getSubMenu();
  }, []);

  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem("token");
    if (user) {
      const item = JSON.parse(user);
      const now = new Date();
      const decodeToken = item ? jwtDecode(item.value) : null;
      if (decodeToken && decodeToken.exp && (decodeToken.exp * 1000) >= now.getTime() && now.getTime() <= item.expiry) {
        setIsLogin(true);
      } else {
        localStorage.removeItem("token");
        setIsLogin(false);
      }
    } else {
      localStorage.removeItem("token");
      setIsLogin(false);
    }

    if (localStorage.getItem('removeBanner')) {
      if (localStorage.getItem('removeBanner') === true) {
        localStorage.setItem('removeBanner', false);
        setShowBanner(true);
      }
      else {
        setShowBanner(false);
      }
    }
    else {
      setShowBanner(true);
    }
  });

  // navbar toggle
  const [isOpen, setIsOpen] = useState(false);

  // header sidebar
  const toggle = () => {
    document.body.classList.add(styles.headerSidebarOpen);
    setIsOpen(true);
  }

  const toggleClose = () => {
    document.body.classList.remove(styles.headerSidebarOpen);
    setIsOpen(false);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => toggleClose());

  const getSubMenu = () => {
    let aboutArray = ["about-us", "top-writers", "reviews", "price"];
    let writingArray = ["tools/free-plagiarism-checker"];

    let serviceObj = [];
    if (props.sub_menu_data !== undefined && props.sub_menu_data) {
      (props.sub_menu_data).forEach(element => {
        serviceObj.push(element.file_name);
      });
      serviceObj.push("services");
    }

    let currentPath = router.asPath;
    let path = currentPath.substring(1, currentPath.length);

    if (path === "contact-us") {
      contactUsRef.current?.classList.add('active');
      setMobileMenuActivation("contact-us-id-mobile");
    }
    else if (path === "how-it-works") {
      howItWorksRef.current?.classList.add('active');
      setMobileMenuActivation("how-it-works-id-mobile");
    }
    else if (path === "coupons") {
      couponRef?.current?.classList.add('active');
      setMobileMenuActivation("coupons-id-mobile");
    }
    else if (aboutArray.includes(path)) {
      aboutRef.current?.classList.add('active');
      setMobileMenuActivation("about-id-mobile");
    }
    else if (writingArray.includes(path)) {
      writingToolRef.current?.classList.add('active');
      setMobileMenuActivation("writing-tool-id-mobile");
    }
    else if (router.pathname.includes('examples')) {
      samplesRef.current?.classList.add('active');
      setMobileMenuActivation("samples-id-mobile");
    }
    else if (serviceObj.includes(path)) {
      serviceRef.current?.classList.add('active');
      setMobileMenuActivation("service-id-mobile");
    }
  }

  const setMobileMenuActivation = (mobile_menu_id) => {
    setTimeout(() => {
      const elementExistOrNot = document.getElementById(mobile_menu_id);
      if (document.body.contains(elementExistOrNot)) {
        document.getElementById(mobile_menu_id).classList.add('active');
      }
    }, 2000);
  }

  const navBarStyles = () => {
    if (currLocale === UK) return styles.navbarUk;
    if (currLocale === CA) return styles.navbarCa;
    if (currLocale === UAE) return styles.navbarUae;
    return '';
  }

  const localeHref = DEFAULT_LOCALES_LIST.includes(currLocale) ? "" : `/${currLocale}`;
  const localeHrefLogo = DEFAULT_LOCALES_LIST.includes(currLocale) ? "/" : `/${currLocale}`;

  return <>
    <div id="navbar" className={`${styles.topNavbar} ${scroll ? styles.navbarScroll : ""}`}>

      {isStudentBeanOpen && (
          <div className={styles.studentBeans}>
            <Container className={`${styles.studentBeansContainer}`}>
              <div className={`d-flex ${styles.imageContainer}`}>
                <Image alt={"student bean"} src={studentBeans}
                       width={220} height={40}
                       quality={80}/>
              </div>
              <p>Exclusive Discount Alert! Save Big With Student Beans! Join Now For Unbeatable Deals.</p>
              <Link
                href={"/student-discount"}
                className={`${styles.btn} btn outline-btn d-block`}>
                GET IT NOW
              </Link>
            </Container>
            <Button className={`${styles.btnClose}  btn outline-btn d-block`}
                    onClick={()=>setIsStudentBeanOpen(false)}>X</Button>
          </div>
      )}

      {/* Navbar */}
      <Navbar expand="md"
              className={`${scroll ? styles.scroll : ""} ${styles.navbar} ${isStudentBeanOpen ? "" : styles.navbarWithoutStudentBean}`}>
        <Container>
          <Navbar.Brand
            href={localeHrefLogo}
            onClick={
              router.pathname === "/" ? (e) => e.preventDefault() : null
            }>
            {width > 991 ? (
                  <div className="desklogo d-flex align-items-center">
                    <div className="logoDiv"><Img alt="logo" src={logoDesktop} width={40} height={40} /></div>
                    <div className="ms-1 me-1">Cheapest<span>Essay</span></div>
                    {currLocale === UK && <span className={styles.flag}><Image alt="UK Flag" src={ukFlag} width={30} height={20} /></span>}
                    {currLocale === CA && <span className={styles.flag}><Image alt="Canada Flag" src={canadaFlag} width={30} height={20} /></span>}
                    {currLocale === UAE && <span className={styles.flag}><Image alt="UAE Flag" src={uaeFlag} width={30} height={20} /></span>}
                  </div>

            ) : (
                <div className="desklogo">
                  <Image src={logo} alt="logo" width={40} height={40} />
                  {currLocale === UK && <span className={styles.flag}><Image alt="UK Flag" src={ukFlag} width={30} height={20} /></span>}
                  {currLocale === CA && <span className={styles.flag}><Image alt="Canada Flag" src={canadaFlag} width={30} height={20} /></span>}
                  {currLocale === UAE && <span className={styles.flag}><Image alt="UAE Flag" src={uaeFlag} width={30} height={20} /></span>}
                </div>
            )}
          </Navbar.Brand>
          {width<=991 ? (
              <div className={`${styles.disNavRight} d-flex`}>
                {isLogin ? (
                    <Nav.Link
                        href="/my-orders"
                        className={`${styles.btn} btn outline-btn d-block d-lg-none`}
                    >
                      My Account
                    </Nav.Link>
                ) : (
                    <Nav.Link
                        href="/login"
                        className={`${styles.btn} btn outline-btn d-block d-lg-none`}
                    >
                      Login
                    </Nav.Link>
                )}
                <Link
                  href={"/order"}
                  locale={linkLocale}
                  className={`${styles.btn} btn secondary-btn d-lg-none`}>
                  
                    Order Now
                  
                </Link>
              </div>
          ) : (
              <Navbar.Collapse className={`${styles.navbarCollapse} d-none d-lg-block`}>
                <Nav className={`m-auto ${styles.navbarNav} ${navBarStyles()}`}>
                  {/* Services */}
                  <div className={styles.navItem}>
                    <Dropdown onMouseLeave={() => setShowDropdown(false)}
                              onMouseOver={() => setShowDropdown(true)}>
                      <Dropdown.Toggle className={`${styles.btn} removeLink`}>
                    <span
                        ref={serviceRef}
                        className={styles.navLink}
                        id="service-id"
                        title="Services">Services</span>
                      </Dropdown.Toggle>
                      {showDropdown && <div className={`${styles.dropdownMenu} dropdown-menu`}>
                        {props.sub_menu_data?.map((subitem) => {
                          return (
                              <div className={`${styles.dropdownItem} dropdown-item`} key={subitem.file_name}>
                                <Nav.Link
                                    href={`${localeHref}/${subitem.file_name}`}
                                    title={subitem.display_text}
                                    className={styles.navLink}
                                >
                                  {subitem.display_text}
                                </Nav.Link>
                              </div>
                          );
                        })}

                        {DEFAULT_LOCALES_LIST.includes(currLocale) && (
                            <div className={`${styles.dropdownItem} dropdown-item`}>
                              <Nav.Link
                                  href={"/services"}
                                  className={`${styles.navLink} btn secondary-btn`}
                                  title="All Services"
                              >
                                All Services
                                <RightArrowIcon stroke="#12245A"/>
                              </Nav.Link>
                            </div>
                        )}
                      </div>
                      }
                    </Dropdown>
                  </div>

                  {/* How it works */}
                  <div className={styles.navItem}>
                    <Nav.Link
                        innerRef={howItWorksRef}
                        href={`${localeHref}/how-it-works`}
                        title="How it Works"
                        className={styles.navLink}>How it Works</Nav.Link>
                  </div>

                  {/* Coupons */}
                  {DEFAULT_LOCALES_LIST.includes(currLocale) ? (
                      <div className={styles.navItem}>
                        <Nav.Link
                            innerRef={couponRef}
                            href={"/coupons"}
                            title="Coupons" className={styles.navLink}>Coupons</Nav.Link>
                      </div>
                  ) : ""
                  }

                  {/* Writing Tools */}
                  <div className={styles.navItem}>
                    <Dropdown>
                      <Dropdown.Toggle className={`${styles.btn} removeLink`}>
                    <span
                        ref={writingToolRef}
                        className={styles.navLink}
                        id="writing-tool-id"
                        title="Tools">Tools</span>
                      </Dropdown.Toggle>
                      <div className={`${styles.dropdownMenu} dropdown-menu`}>
                        <div className="dropdown-item">
                          <Nav.Link id="plagiarism-tool-id" href={"/tools/free-plagiarism-checker"} title="Plagiarism Checker" className={styles.navLink}>Plagiarism Checker<div className={styles.lbl}>New</div>
                          </Nav.Link>
                        </div>
                      </div>
                    </Dropdown>
                  </div>

                  {/* Samples */}
                  <div className={styles.navItem}>
                    <Nav.Link
                        innerRef={samplesRef}
                        href={"/examples"}
                        title="Samples" className={styles.navLink}>Samples</Nav.Link>
                  </div>

                  {/* Articles */}
                  <div className={styles.navItem}>
                    <Nav.Link
                        id="articles-id"
                        href={"/articles"}
                        title="Articles" className={styles.navLink}>Articles</Nav.Link>
                  </div>

                  {/* About */}
                  <div className={styles.navItem}>
                    <Dropdown>
                      <Dropdown.Toggle className={`${styles.btn} removeLink`}>
                        <span ref={aboutRef} className={styles.navLink} id="about-id" title="About">About</span>
                      </Dropdown.Toggle>
                      <div className={`${styles.dropdownMenu} dropdown-menu`}>
                        <div className="dropdown-item">
                          <Nav.Link href={`${localeHref}/about-us`}
                                    title="About" className={styles.navLink}>About</Nav.Link>
                        </div>
                        <div className="dropdown-item">
                          <Nav.Link href={`${localeHref}/top-writers`}
                                    title="Writers" className={styles.navLink}>Writers</Nav.Link>
                        </div>
                        <div className="dropdown-item">
                          <Nav.Link href={`${localeHref}/reviews`}
                                    title="Reviews" className={styles.navLink}>Reviews</Nav.Link>
                        </div>
                        <div className="dropdown-item">
                          <Nav.Link href={`/app`}
                                    title="App" className={styles.navLink}>App</Nav.Link>
                        </div>
                        <div className="dropdown-item">
                          <Nav.Link href={`${localeHref}/price`}
                                    title="Pricing" className={styles.navLink}>Pricing</Nav.Link>
                        </div>
                        <div className="dropdown-item">
                          <Nav.Link innerRef={contactUsRef}
                                    href={`${localeHref}/contact-us`}
                                    title="Contact" className={styles.navLink}>Contact</Nav.Link>
                        </div>
                      </div>
                    </Dropdown>
                  </div>
                </Nav>
                <form className={`d-flex ${styles.headerButton} position-relative`}>
                  {isLogin ? (
                      <Link
                        href={"/my-orders"}
                        locale={false}
                        className={`${styles.btn} btn outline-btn d-none d-lg-block`}>
                        
                          My Account
                        
                      </Link>
                  ) : (
                      <Link
                        href={"/login"}
                        locale={false}
                        className={`${styles.btn} btn outline-btn d-none d-lg-block`}>
                        
                          Login
                        
                      </Link>
                  )}
                  <Link
                    href={"/order"}
                    locale={linkLocale}
                    className={`${styles.btn} btn secondary-btn`}>
                    
                      Order Now
                    
                  </Link>
                </form>
              </Navbar.Collapse>
          )}
        </Container>
      </Navbar>
    </div>
    {width <= 991 && <div onClick={toggle}
                          className={`d-lg-none d-md-block ${styles.navbarTogglerIcon} open ${styles.navbarToggler} ${isStudentBeanOpen ? "" : styles.studentBeansClose} ${!showBanner ? "removeBanner" : ""}`}>
        <span/>
        <span/>
        <span/>
    </div>
    }
    {isOpen ? (
      <div className={styles.sidebarOverlay}>
        <div ref={wrapperRef}>
          <Navbar.Collapse className={`${styles.navbarCollapse} ${isOpen ? "show" : ""} d-lg-none d-block`}>
            <div className={styles.mobileNav}>
              <Nav className={`m-auto ${styles.navbarNav} ${navBarStyles()}`}>
                {/* Service */}
                <div className={styles.navItem}>
                  <Dropdown>
                    <Dropdown.Toggle className={styles.navItem}>
                      <span
                        className={styles.navLink}
                        id="service-id-mobile"
                        title="Services">Services</span>
                    </Dropdown.Toggle>
                    <div className={`${styles.dropdownMenu} dropdown-menu`}>
                      {props.sub_menu_data && props.sub_menu_data.map(subitem => (
                        <Dropdown.Item as="button" key={subitem.file_name} className={styles.navItem}>
                          <Nav.Link className={styles.navLink}
                                    href={`${localeHref}/${subitem.file_name}`}
                                    title={subitem.display_text}>
                            {subitem.display_text}
                          </Nav.Link>
                        </Dropdown.Item>
                      )
                      )}
                      {DEFAULT_LOCALES_LIST.includes(currLocale) ? (
                        <Dropdown.Item as="button" className={styles.navItem}>
                          <Nav.Link href="/services" className={styles.navLink}>
                            All Services <RightArrowIcon stroke="#ffffff" />
                          </Nav.Link>
                        </Dropdown.Item>
                      ) : ""}
                    </div>
                  </Dropdown>
                </div>

                {/* How it works */}
                <div className={styles.navItem}>
                  <Nav.Link id="how-it-works-id-mobile"
                    title="How it Works"
                            href={`${localeHref}/how-it-works`}
                            className={styles.navLink}>
                    How it Works
                  </Nav.Link>
                </div>

                {/* Coupons */}
                {DEFAULT_LOCALES_LIST.includes(currLocale) ? (
                  <div className={styles.navItem}>
                    <Nav.Link
                      id="coupons-id-mobile"
                      href="/coupons"
                      title="Coupons" className={styles.navLink}>Coupons</Nav.Link>
                  </div>
                ) : ""
                }

                {/* Writing Tools */}
                <div className={styles.navItem}>
                  <Dropdown>
                    <Dropdown.Toggle className={styles.navItem}>
                      <span
                        className={styles.navLink}
                        id="writing-tool-id-mobile"
                        title="Tools">Tools</span>
                    </Dropdown.Toggle>
                    <div className={`${styles.dropdownMenu} dropdown-menu`}>
                      <Dropdown.Item as="button">
                        <Nav.Link id="plagiarism-tool-id-mobile"
                          href="/tools/free-plagiarism-checker"
                          title="Plagiarism Checker" className={styles.navLink}>Plagiarism Checker<div className={styles.lbl}>New</div>
                        </Nav.Link>
                      </Dropdown.Item>
                    </div>
                  </Dropdown>
                </div>

                {/* Samples */}
                <div className={styles.navItem}>
                  <Nav.Link
                    id="samples-id-mobile"
                    href="/examples"
                    title="Samples" className={styles.navLink}>Samples</Nav.Link>
                </div>

                {/* Articles */}
                <div className={styles.navItem}>
                  <Nav.Link
                    id="articles-id-mobile"
                    href="/articles"
                    title="Articles" className={styles.navLink}>Articles</Nav.Link>
                </div>

                {/* About */}
                <div className={styles.navItem}>
                  <Dropdown>
                    <Dropdown.Toggle className={styles.navItem}>
                      <span className={styles.navLink} id="about-id-mobile" title="About">About</span>
                    </Dropdown.Toggle>
                    <div className={`${styles.dropdownMenu} dropdown-menu`}>
                      <Dropdown.Item as="button">
                        <Nav.Link  href={`${localeHref}/about-us`}  title="About" className={styles.navLink}>About</Nav.Link>
                      </Dropdown.Item>
                      <Dropdown.Item as="button">
                        <Nav.Link href={`${localeHref}/top-writers`} title="Writers" className={styles.navLink}>Writers</Nav.Link>
                      </Dropdown.Item>
                      <Dropdown.Item as="button">
                        <Nav.Link href={`${localeHref}/reviews`} title="Reviews" className={styles.navLink}>Reviews</Nav.Link>
                      </Dropdown.Item>
                      <Dropdown.Item as="button">
                        <Nav.Link href={`/app`} title="App" className={styles.navLink}>App</Nav.Link>
                      </Dropdown.Item>
                      <Dropdown.Item as="button">
                        <Nav.Link href={`${localeHref}/price`} title="Pricing" className={styles.navLink}>Pricing</Nav.Link>
                      </Dropdown.Item>
                      <Dropdown.Item as="button">
                        <Nav.Link id="contact-us-id-mobile" title="Contact Us" href={`${localeHref}/contact-us`} className={styles.navLink}>Contact</Nav.Link>
                      </Dropdown.Item>
                    </div>
                  </Dropdown>
                </div>
              </Nav>
              <ul className={`${styles.download} d-flex justify-content-center align-items-center`}>
                <li>
                  <a href="https://apps.apple.com/us/app/cheapest-custom-writing-papers/id1447217562" target="_blank" rel="noreferrer">
                    <Image src="/app-store.webp" alt="app store" width={110} height={33} title="Appstore" />
                  </a>
                </li>
                <li className="ms-2">
                  <a href="https://play.google.com/store/apps/details?id=com.cheapestessay.service" target="_blank" rel="noreferrer">
                    <Image src="/google-play.webp" alt="google play" width={110} height={33} title="GooglePlay" />
                  </a>
                </li>
              </ul>
            </div>
            <form className={`d-flex ${styles.headerButton}`}>
              {isLogin ? (
                <Link
                  href="/logout"
                  locale={false}
                  passHref
                  className={`btn outlineSecondaryBtn d-lg-block ${styles.loginActionBtn}`}>
                  
                    Logout
                  
                </Link>
              ) : (
                <Link
                  href="/login"
                  locale={false}
                  className={`btn outlineSecondaryBtn d-lg-block ${styles.loginActionBtn}`}
                  onClick={() => localStorage.setItem('orderRight', data)}>
                  
                    Login
                  
                </Link>
              )}
            </form>
          </Navbar.Collapse>
          <div className={`${styles.navbarTogglerIcon} ${styles.close}`} onClick={toggleClose}>
            <span />
            <span />
            <span />
          </div>
        </div>
      </div>
    ) : null}
  </>;
}

export default Header;